import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import componentFinder from '../util/componentFinder';
import Button from '../../components/Button';

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow:'0px 0px 0px',
  },
  button : {
    fontSize:'1.2512rem',
    padding: '5px 58px',
    borderRadius:'0px',
    fontWeight:600,
    color:theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
    },
  },
  cardContent: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderTop: 'double 4px  #FDB813',
    overflow: 'hidden',
    maxHeight: '280px',
  },
  productTitle: {
    fontSize: '1.39196rem',
    fontWeight:'bold',
    textTransform: 'uppercase',
  },
  title: {
    fontSize: '2.00192rem',
    fontWeight: 600,
  },
  boxTitle: {
    fontSize: '1.73995rem',
    fontWeight: 600,
  },
}));

const getBackgroundColor = backgroundColor =>
  backgroundColor === 'dark' ? 'secondary.main' : '';

export default props => {
  const data = props.data[props.componentId]
  
  const classes = useStyles();


  const bgcolor = getBackgroundColor(data.backgroundColor);

  const logoOrCard = (input, product, index) => {
    if(input === 'Card'){
      return (
        <Grid item sm={6} md={5} lg={4} key={index}>
          <Card className={classes.card}>
              <CardMedia
                component="img"
                alt={product.boxTitle}
                height="380"
                image={product.image.fluid.src}
                title={product.title}
              />

              <CardContent className={classes.cardContent}>
                <Box 
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="left"
                  minHeight="125px"
                >
                  <Typography variant='h2' component='h2'
                    className={classes.productTitle}
                    align='left'
                  >
                    {product.title}
                  </Typography>
                  <Box component="div" my={2} textOverflow="ellipsis" overflow="hidden">
                    <Typography component="span" variant="h6">{product.duration}</Typography>
                    <Typography component="span" variant="h6">{product.period}</Typography>
                  </Box>
                </Box>
                {product.buttonEnabled ? (
                  <Grid
                    item
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                  >
                    <Box mt={2} mb={4}>
                      <Button
                        className={classes.button}
                        variant="contained"
                        size="large"
                        disableElevation
                        color={product.buttonColor}
                        to={`/${product.buttonLinkToPage.name}`}
                      >
                        {product.buttonLabel}
                      </Button>
                    </Box>
                  </Grid>
                ) : (
                  ''
                )}
              </CardContent>
          </Card>
        </Grid>
      )
    }
    return(
      <Grid item sm={6} md={5} lg={2} key={index}>
        <Card className={classes.card}>
          <CardMedia
            component="img"
            alt={product.boxTitle}
            image={product.image.fluid.src}
            title={product.title}
          />
          </Card>
      </Grid>
    )
  }

  return (
    <Box bgcolor={bgcolor} py={5}>
      <Container maxWidth="lg">
        <Typography className={classes.title} component="h2" gutterBottom>
          <Box textAlign="center">{data.title}</Box>
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
        <Box textAlign="center">{data.subtitle}</Box>
      </Typography>

        <Grid
          container
          direction="row"
          justify="space-around"
          spacing={3}
        >
          {data.products.map(
            (
              product,
              index,
            ) => {
              return (
                <>
                {logoOrCard(data.logoOrCard, product, index)}
                </>
              );
            },
          )}
        </Grid>
      </Container>
    </Box>
  );
};
