import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import componentFinder from "../util/componentFinder";

const useStyles = makeStyles((theme) => ({
  cardmedia: {
    objectFit: "contain",
  },
  title: {
    fontSize: "2.00192rem",
    fontWeight: 600,
  },
}));

const getBackgroundColor = (backgroundColor) =>
  backgroundColor === "dark" ? "secondary.main" : "";

export default (props) => {
  const data = props.data[props.componentId];

  const classes = useStyles();

  const bgcolor = getBackgroundColor(data.backgroundColor);

  return (
    <Box bgcolor={bgcolor} py={5}>
      <Container maxWidth="lg">
        <Typography className={classes.title} component="h2" gutterBottom>
          <Box textAlign="center">{data.title}</Box>
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom>
          <Box textAlign="center">{data.subtitle}</Box>
        </Typography>
        <Grid container direction="row" justify="space-around" spacing={3}>
          {data?.image?.map((image, index) => {
            const imageUrl = image.image?.fluid?.src;
            if (!imageUrl) {
              return null;
            }
            return (
              <Grid item sm={6} md={5} lg={4} key={index}>
                <CardMedia
                  className={classes.cardmedia}
                  component="img"
                  height="380"
                  image={imageUrl}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};
